import PropTypes from "prop-types";
import { useRef, useState } from "react";

import Dispatcher from "@/components/types/Dispatcher";
import classNames from "classnames";
import cn from "./Carousel.module.css";

const LABELS = {
  flexbox: "Flexbox",
  backgrounds: "Backgrounds",
  colors: "Colors",
  "margin-padding-gap": "Margin/Padding/Gap",
  tables: "Tables",
  transforms: "Transforms",
  "transitions-animations": "Transitions and Animations",
  typography: "Typography",

  "align-basic": "Flexbox",
  "container-basic": "Flexbox",
  "intro-container": "Flex Forms",
  "intro-basic": "Column System",
  "alpha-hex": "Transparency",
  "gap-basic": "Gaps",
  "gradient-conic": "Gradients",
  "gradient-linear-multiple": "Linear Gradients",
  "image-gradient": "Background Overlays",
  "order-pagination": "Pagination",
  "grow-basic": "Flex Grow",
  "justify-basic": "Flex Justify",
  "other-blend-mode": "Blend Modes",
  "horizontal-auto": "Centering",
  "padding-balance": "Visual Balance",
  "horizontal-flexbox": "Flex Centering",
  "horizontal-negative": "Negative Margins",
  "intro-space": "Balanced Spacing",
  "intro-interchangeable": "Choosing between Margin/Padding/Gap",
  "vertical-margin-not-last": "Pseudo Elements",
  "span-col": "Tables",
  "card-basic": "Perspective",
  "card-hover": "Flip Card",
  "rotate-3d": "3D Transforms",
  "translate-list": "Translations",
  "property-transform": "Transitions",
  "direction-basic": "Animations",
  "playstate-basic": "Playstate",
  "timing-basic": "Animations",
  "align-start": "Text Align",
  "web-typo": "Typography",
  "dark-palette": "Dark Mode",
  "primary-secondary": "Dark Mode",

  "size-simple": "Typography",
  "property-several": "Hover Transitions",
  "perspective-parent": "3D Transforms",
  "padding-four": "Padding",
  "margin-auto": "Centering",
  "gap-both": "Flex Gaps",
  "align-all": "Flex Alignment",
  "grow-more": "Flex Grow",
  "justify-end": "Flex Justify",
  "alpha-rgba": "Transparency",
  "dark-buttons": "Dark Mode",
  "gradient-radial": "Gradients",
  "multiple-image": "Backgrounds",
  "intro-form": "Responsive Form",
  "other-origin": "2D Transforms",
};

function Carousel({ kind, componentIds, byId }) {
  const carouselRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [offset, setOffset] = useState(0);

  const handleTabClick = (e, index) => {
    e.preventDefault();
    setCurrentIndex(index);

    const offset = e.target.offsetLeft;
    const half = e.target.offsetWidth / 2;
    const midpoint = carouselRef.current.offsetWidth / 2;

    if (offset + half > midpoint) {
      setOffset(offset + half - midpoint);
    } else {
      setOffset(0);
    }
  };

  const carouselCN = classNames({
    [cn.carousel]: true,
    [cn[`is-${kind}`]]: true,
    [cn[`is-${currentIndex + 1}`]]: true,
  });

  const tabsStyle = {
    transform: `translateX(-${offset}px)`,
  };

  const carouselStyle = {
    "--index": currentIndex,
  };

  return (
    <div className={cn.main}>
      <nav className={cn.tabs} style={tabsStyle}>
        {componentIds.map((tab, index) => {
          const tabCN = classNames({
            [cn.tab]: true,
            [cn.active]: index === currentIndex,
          });

          const label = LABELS[tab] || tab;

          return (
            <button
              key={tab}
              className={tabCN}
              onClick={(e) => handleTabClick(e, index)}
            >
              <strong>{label}</strong>
            </button>
          );
        })}
      </nav>

      <div ref={carouselRef} className={carouselCN} style={carouselStyle}>
        <div className={cn.list}>
          {componentIds.map((courseId, index) => {
            const component = byId[courseId];
            component.kind = kind;

            const componentStyle = {
              "--n": index,
              "--offset": index - currentIndex,
            };

            const courseCN = classNames({
              [cn.component]: true,
              [cn.current]: index === currentIndex,
            });

            return (
              <div key={courseId} className={courseCN} style={componentStyle}>
                <Dispatcher part={component} index={999} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

Carousel.propTypes = {
  kind: PropTypes.string,
  componentIds: PropTypes.array,
  byId: PropTypes.object,
};

export default Carousel;
